
import { defineComponent,onBeforeMount,ref } from "vue";
import helper from '@/utils/helper'
export default defineComponent({
  name: "home",
  setup() {

    const mobile = ref(false);
    onBeforeMount(()=>{

      if(helper.is_mobile()){
        mobile.value = true;
      }
    })

    return {
      mobile
    };
  },
  components: {

  },
});
