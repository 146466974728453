import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-066f4594"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home_wrapper" }
const _hoisted_2 = { class: "home_box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "home_info" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.mobile)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            width: "750",
            class: "home_img",
            src: require('@/assets/image/common/logoMain2.png')
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "home_img",
            src: require('@/assets/image/common/home_img.png')
          }, null, 8, _hoisted_4)),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.mobile)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "info_img",
              src: require('@/assets/image/common/logoEng.png')
            }, null, 8, _hoisted_6))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "info_img",
              src: require('@/assets/image/common/logoEng2.png')
            }, null, 8, _hoisted_7))
      ])
    ])
  ]))
}